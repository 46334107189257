._headerBar {
    position: absolute;
    top: 0;
    width: 100%;
    height: 50px;
    background-color:  white;
    z-index: 22;
    display: flex;
    box-shadow: 0 4px 2px -2px gray;
}
._headerLogo {
    width: 80%;
}

._headerIcon {
    height: 50px;
    margin-left: 20px;
}

._headerQuickLinks{
    width: 30%;
    position: relative;
    display: inline-block;
}
._headerMenuDiv {
    display: inline-block;
    height: 50px;
    position: absolute;
    right: 100px;
    &:hover {
        cursor: pointer;
    }
    
}
._headerMenuDiv:hover ._dropdownContent {
    display: inline-block;
}

._headerMenu {
    height: 50px;
    border-radius: 10%;
}

._dropdownContent {
    position: absolute;
    right: 0px;
    top: 50px;
    display: none;
    border-radius: 5%;
    box-shadow: 0 0 56px 8px rgba(0, 0, 0, .2);
    width: 200px;
    background-color: white;
    text-align: center;
    z-index: 22;
}

._quickRow {
    margin-top: 7px;
    margin-bottom: 7px;
    height: 25px;
    &:hover {
        background-color: rgb(220, 220, 220);
    }
}
._quickLink {
    text-decoration: none;
    color: rgb(0, 56, 69);

}

._projectDropdownContent {
    position: absolute;
    left: -150px;
    display: none;
    width: 150px;
    background-color: white;
    z-index: 22;
    box-shadow: 0 0 56px 8px rgba(0, 0, 0, .2);
    border-radius: 5%;
}

._projectRow {
    position: relative;
    &:hover ._projectDropdownContent{
        display: inline-block;
    }
}

._projectSubRow{
    margin-top: 7px;
    margin-bottom: 7px;
    height: 25px;
    &:hover {
        background-color: rgb(220, 220, 220);
    }

}

._projectArrow {
    height: 14px;
    width: 14px;
    position: absolute;
    left: 4px;
    top: 3px;
}