@font-face {
    font-family: 'pixelFont';
    src: url('./bulky-pixels.regular.ttf');
  }

@keyframes animateSky {
    0% {
        background-position: top left;
    }
    100% {
        background-position: bottom right;
    }
}



._titlePageMainDiv {
    background-color: rgb(255, 255, 255);
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    background-image: url("../static/sky.jpg");
    background-size:200%;
    overflow: hidden;
    position: absolute;
    animation: animateSky 40s infinite alternate;
}

._pixelPigeon {
    position: absolute;
    height: 50px;
    width: 50px;
    top: 20%;
    left: 100%;

}

._pixelCloud {
    position: absolute;
    height: 100px;
    width: 200px;
    bottom: 20%;
    left: -250px;
}



._titleContainer {
    display: flex;
    justify-content: center;
}

._titleLink {
    position: relative;
    display: block;
    text-align: center;
    margin: auto;
    text-decoration: none;
    color: #000000;
    font-size: 80px;
    font-family: pixelFont;
    overflow: hidden;
    &:hover ._titleInitial{
        transform: translateX(100%);
    }
    &:hover ._titleSecond {
        transform: none;
    }

}

._titleSpan {
    transition: transform 0.2s ease-out
}

._titleInitial {
    display: inline-block;
    padding: 10px;
}

._titleSecond {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: inline-block;
    padding: 10px;
    transform: translateY(-100%);
}