._blogLibrary {
    padding-top: 70px;
    overflow: scroll;
    scrollbar-width: none;
}

._blogLibraryContent {
    display: flex;
    padding-left: 150px;
    padding-right: 150px;
}

._blogsDisplay {
    min-width: 450px;
    height: 500px;
    padding-bottom: 50px;

    margin-right: 100px;
    text-align: center;
    position: relative;
}
._blogsDisplayHeader {
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 24px;
}

._blogsDisplayBody {

    box-shadow: 3px 3px 3px 3px #545454;
    border-radius: 5%;
    height: 80%;
    position: absolute;
    bottom: 5%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

._blogsDisplayBlogContainer {
    height: 100%;
    width: 100%;
    overflow: scroll;
    scrollbar-width: none;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

._loader {
    border-radius: 50%;
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #000000; /* Blue */
    width: 100px;
    height: 100px;
    animation: 2s spin linear infinite;
}
._blogLinkContainer {
    margin-top: 10px;
    margin-bottom: 10px;
}
._blogLink{    
    all: unset;
    margin-top: 5px;
    margin-bottom: 5px;
    text-decoration: underline;
    font-size: 18px;
    animation: all 2s ease-in;
    &:hover {
        cursor: pointer;
    }
}
._blogDate {
    font-size: 12px;
}

._blogMainDiv {
    height: 100vh;
    width: 100vw;
    padding-bottom: 70px;
}

._singleBlog {
    display: flex;
    margin-left: 50px;
    padding-top: 30px;
    width: 80vw;
    min-width: 800px;
    position: relative;
    padding-bottom: 70px;
}

._blogImageDiv {
    width: 100px;
    height: 100%;
}
._blogBodyDiv {
    display: inline;
    width: 100%;
    margin-left: 20px;
    padding-bottom: 12px;
    font-family: 'Times New Roman', Times, serif;
    padding-left: 16px;
}
._blogTitle {
    text-align: center;
    font-size: 28px;
}
._blogContent {
    margin-top: 16px;
    font-size: 18px;
    line-height: 1.5;
}

._blogAfterword {
    margin-top: 8px;
    font-size: 14px;
    text-align: center;
}
._blogBrowseMore {
    font-size: 20px;
    background: linear-gradient(to right, rgb(106, 168, 243) 0%, rgb(165, 218, 236) 80%, rgb(165, 218, 236) 100%);
    border-radius: 1.65rem;
    padding: 10px;
    border: none;
    transition: all 0.1s ease-in;
    &:hover {
        cursor: pointer;
        transform: scale(1.05);
    }

    &:active {
        transform: scale(0.95);
    }
}

._blogImage {
    width: 100px;
    height: 100px;
}
._blogFooter {
    position: absolute;
    bottom: 70px;
    left: 0;
    right: 0;
    text-align: right;
    font-size: 10px;
}