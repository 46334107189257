._footerMainDiv {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    background-color: rgb(255, 246, 222);
    overflow: hidden;
    color: rgb(0, 0, 0);
    display: flex;
    z-index: 22;

}
._footerText {
    position: absolute;
    left: 5%;
    top: 20%;
    max-width: 50vw;
    font-size: 30px;
}
._exitLink {
    text-decoration: none;
    display: inline-block;
    font-family:'Times New Roman', Times, serif;
    &:hover {
        transform: scale(1.2);
    }
}

._footerButtons {
    margin: 5px;
    position: absolute;
    right: 0;
    top: 20%;
    display: flex;
    width: 20vw;
}

._footerButton {
    padding-right: 30px;
}

._footerImage {
    max-width: 30px;
    max-height: 30px;
    &:hover {
        transform: translateY(-10%);
    }
}
._copyRight {
    margin-top:4px;
    font-size: 14px;
}