.APP {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  scrollbar-width: none;
}



.APP::-webkit-scrollbar {
  display: none;
}