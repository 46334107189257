@font-face {
    font-family: 'medieval';
    src: url('./blackchancery.regular.ttf');
}
@font-face {
    font-family: 'hangMan';
    src: url('./evil-empire.regular.otf');
}
@font-face {
    font-family: 'ethno';
    src: url('./ethnocentric.rg-regular.otf');
}
._avalonDivBack {

    background-size: 150%;
    background-position-x: 20%;
    background-position-y: 100%;
    overflow-y: scroll;
    scrollbar-width: none;

}

._projectDisplay {

    position: relative;
    display: flex;
    height: 100vh;
    width: 100vw;
    min-width: 1100px;
    padding-bottom: 60px;


}
._projectTechTitle {
    margin-top: 40px;
    margin-left: 30px;
    font-size: 32px;

}

._medievalFont {
    font-family: medieval;
}
._hangManFont {
    font-family: hangMan;
}
._ethnoFont {
    font-family: ethno;
}

._projectDesc {
    margin-top: 50px;
    width: 550px;
    overflow-y: scroll;
    scrollbar-width: none;
}
._projectDemo{
    margin-top: 50px;
    text-align: center;
    min-width: 550px;
    margin-left: 50px;
}

._projectTitle {
    margin-top: 30px;
    margin-left: 30px;
    font-size: 48px;

}
._projectBody {
    margin-top: 50px;
    font-size: 16px;
    margin-left: 20px;

}
._gallerySlide {
    width: 500px;
    height: 500px;
    position: absolute;
    background-color: #eee;
    text-align: center;
    transition: opacity 0.4s;
    opacity: 1;
    z-index: 1;
}
._gallerySlide:before {
    content: attr(annot);
    display: block;
    position: absolute;
    left: 20px;
    bottom: 20px;
    color: rgba(255, 255, 255, 0.9);
    font-size: 14px;
    font-weight: 300;
    z-index: 12;
    opacity: 0;
    transition: opacity 0.3s;
    text-shadow: 0 0 1px #000;
}

._gallerySlide:after {
    content: attr(slide);
    display: block;
    position: absolute;
    bottom: 0;
    transition: opacity 0.3s;
    width: 100%;
    height: 80px;
    opacity: 0;
    background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.5));
    text-align: left;
    text-indent: 449px;
    line-height: 101px;
    font-size: 13px;
    color: rgba(255, 255, 255, 0.9);
    text-shadow: 0 0 1px #000;
}

._carousel:hover ._gallerySlide:after,
._carousel:hover .counter,
._carousel:hover ._gallerySlide:before {
    opacity: 1;
}

._galleryImage {

    max-width: 500px;
    max-height: 500px;
}

._galleryCheck {
    top: 0;
    right: 0;
    float: right;
    position: absolute;
    margin-top: 0;
    z-index: 9;
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;
}

._galleryCheck:checked {
    z-index: 8;
}
._galleryCheck:checked + ._gallerySlide {
    opacity: 0;
}
._galleryCheck:checked:nth-child(1):checked {
    z-index: 9;
}
._galleryCheck:nth-child(1):checked {
    z-index: 9;
}
._galleryCheck:nth-child(1):checked + ._gallerySlide{
    opacity: 1;
}

._galleryCheck:nth-child(1):checked ~ ._galleryCheck {
    z-index: 8;
}
._galleryCheck:nth-child(1):checked ~ ._galleryCheck + ._gallerySlide {
    opacity: 0;
}

._galleryCheck:nth-child(1):checked ~ ._galleryCheck:checked {
    z-index: 9;
}

._galleryCheck:nth-child(1):checked ~ ._galleryCheck:checked + ._gallerySlide {
    opacity: 1;
}


._carousel {
    position: relative;
    height: 500px;
    width: 500px;
    overflow: hidden;
    margin: 0 auto;
    
}