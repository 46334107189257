._snakeGameDiv {
    width: 100vw;
    height: 90vh;
    text-align: center;
    justify-content: center;
    padding-top: 10vh;
    position: relative;
    background-image: url('../static/grass.jpg');
}
._snakeGameTitle {
    text-align: center;
    font-family: fantasy;
    font-size: 32px;
}
._snakeGamePlayButtonContainer {

    justify-content: center;
    text-align: center;
    margin-top: 50px;
}

._snakeGamePlayButton {
    all: unset;
    height: 40px;
    font-family:'Courier New', Courier, monospace;
    width: 160px;
    border-radius: 5%;
    background: linear-gradient(45deg, rgb(255, 191, 15),rgb(255, 107, 15),rgb(255, 210, 105),rgb(255, 98, 36),rgb(255, 132, 0));
    background-size: 300%;
    background-position:  0% 50%;
    font-size: 26px;
    transition: 0.3s;
    &:hover {
        cursor: pointer;
        background-position: 100% 50%;

    }
}
._forfeitButton {
    position: absolute;
    top: 450px;
}
@keyframes bouncingKeys {
    0% {
        transform: scale(0.6);
    }
    100% {
        transform: scale(0.7);
    }
}
._snakeArrowKeys {
    animation: 2s bouncingKeys ease-in infinite alternate; 
}
._snakeGameInstructions{
    margin-top: 20px;
    font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    text-align: center;
    justify-content: center;


}
._cancelButton {
    all: unset;
    width: 100px;
    height: 100px;
    box-shadow: 3px 2px rgb(0, 72, 13);
    border-radius: 50%;
    border: 1px black solid;
    background: rgb(255, 56, 56);
    font-size: 24px;
    transition: 0.25s;
    &:hover {
        cursor: pointer;
        box-shadow: none;
        transform:scale(0.95);
    }
}
@keyframes pulseCountDown {
    0%{
        transform: scale(0.5);
    }
    100% {
        transform: scale(1);
    }
}

._countDownNumber {
    position: relative;
    margin-top: 15vh;
    font-size: 44px;
    animation: 1s pulseCountDown ease-in;
}

._snakeGameGrid {
    display: none;
    position: absolute;
    left: 10vw;
    top: 10vh;
    border: 5px dotted brown;
    height: 400px;
    width: 800px;
}

._snakeGameGridBackground {
    background-image: repeating-conic-gradient(#007d15 0% 25%, #E4E4ED 0% 50%);
    background-position: 0 0, 20px 20px;
    background-size: 40px 40px;
    background-color: #E4E4ED;	
    position: absolute;
    height: 400px;
    width: 800px;
    z-index: 0;
    opacity: 0.1;
}

._snakeFood {
    position: absolute;
    height: 20px;
    width: 20px;
}

._snakeHead {
    position: absolute;
    background-color: red;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    animation-fill-mode: forwards;
}

._snakeBody {
    position: absolute;
    background-color: yellow;
    border-radius: 50%;
    height: 20px;
    width: 20px;
}

._moveRight{
    background-color: black;
    width: 100px;
    height: 100px;
    animation: 2s moveRight linear forwards;
}

