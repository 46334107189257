._mainPageDiv{

 
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    scrollbar-width: none;
}



._imageDiv {
    height: 100%;
    justify-content: center;
}



._aboutText {
    height: 100%;
    width: 75%;
    text-align: center;
}
@keyframes gradientMove {
    0% {
        background-position: 0% 50%;
    }
    50%{ 
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

._aboutSection {
    background: linear-gradient(45deg, rgb(255, 125, 38),rgb(255, 188, 55),rgb(255, 218, 124),rgb(253, 236, 193));
    background-size: 400% 400%;
    animation: gradientMove 15s ease-in infinite;
    display: flex;
}

@keyframes typing {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}
@keyframes blinking-caret {
    from, to {border-color: transparent}
    50% {border-color: rgb(149, 0, 0)}
}
._wrapper {
    display: inline-block;
}
._aboutTextTitle {
    font-size: 36px;
    margin: 0 auto;
    font-family:monospace;
    animation: 
        typing 3s steps(40,end) forwards,
        blinking-caret .75s step-end 5;
    animation-delay: .5s;
    overflow: hidden;
    white-space: nowrap;
    border-right: 0.15em solid transparent;
    width: 0;
}
._typewriterContainer {
    margin-top: 150px;
}

._aboutTextDesc {
    font-size: 26px;
    margin: 0 auto;
    font-family:monospace;
    animation: 
        typing 3s steps(40,end) forwards,
        blinking-caret .75s step-end 5;
    animation-delay: 3.75s;
    overflow: hidden;
    white-space: nowrap;
    border-right: 0.15em solid transparent;
    width: 0;
}

._wrapperFooter {
    margin-top: 30px;
    display: inline-block;
}

._aboutTextFooter {
    font-size: 21px;
    margin: 0 auto;
    font-family:monospace;
    animation: 
        typing 3s steps(40,end) forwards,
        blinking-caret .75s step-end infinite;
    animation-delay: 7s;
    overflow: hidden;
    white-space: nowrap;
    border-right: 0.15em solid transparent;
    width: 0;
}

@keyframes pulsate {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
._aboutSectionImage {
    width: 270px;
    height: 270px;
    padding-top: 40px;
    animation: pulsate 2s infinite;
}
._aboutSectionBall {
    width: 100px;
    height: 100px;
    animation: bounce 1s infinite alternate
}
@keyframes bounce {
    from {
        margin-top: 100px;
    }
    to {
        margin-top: 0;
    }

}
._expSection {
    background-image: url('../static/expBackground.jpg');
    justify-items: right;

}
._expSectionContent {
    width: 70%;
    text-align: center;
}

._expSectionTitle {
    padding-top: 50px;
    font-size: 40px;
    font-family:monospace;
}

._expIcon{
    width: 80px;
    height: 80px;
    padding: 30px;
    &:hover {
        transform: scale(1.1);
    }
}

._projectSection {
    position: relative;
    display: flex;
}

._displayProjectDiv {
    width: 500px;
    margin-left: 60px;
    justify-content: center;
    text-align: center;
}
@font-face {
    font-family: "goodTimes";
    src: url('./good-times.rg-regular.otf');
}
._displayProjectDivHeader {
    margin-top: 40px;
    font-size: 36px;
    font-family: goodTimes;
}
._bottomProject {
    position: absolute;
    bottom: 0;
}
._displayProjectDivBody {
    position: relative;
    margin-top: 50px;
    text-align: center;
    justify-content: center;
    box-shadow: rgb(0, 0, 0);
    height: 350px;
}
._expandButton {
    position: relative;
    left: 175px;

    margin-bottom: 40px;
    text-align: center;
    justify-content: center;
    &:hover {
        ._avI {
            width: 200px;
        }
        ._ziI {
            width: 250px;
        }
        ._haI {
            width: 350px;
        }
    }
    &:hover ._expandText {
        color: white;
    }
}
._expandLink {
   
    position: relative;
    display: flex;
    ._av {
        width: 200px;
    }
    ._zi {
        width: 250px;
    }
    ._ha {
        width: 350px;
    }
    height: 40px;
    border-radius: 50%;
    text-decoration: none;
}

._expandText {
    transition: all 0.4s ease;
    position: relative;
    left: 55px;
    color: rgb(0, 0, 0);
    font-size: 28px;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
}

._expandIcon {
    transition: all 0.5s ease;
    position: absolute;
    left: 0;
    width: 40px;
    height: 40px;
    background: rgb(18, 0, 67);
    border-radius: 1.625rem;
}


@keyframes animateSquare {
    from {
        opacity: 0.6;
        transform: scale(1) rotate(0deg);
    }
    to {
        transform: scale(20) rotate(960deg);
        opacity: 0;
    }
}

._projectSquare {
    z-index: -1;
    position: absolute;
    border: solid 1px rgb(184, 183, 183);
    width: 10px;
    height: 10px;
    animation: animateSquare 12s ease-in-out infinite;
    opacity: 0;
    left:50px;
    top: 70px;
}

._projectSquare:nth-child(2n) {
    border-color: rgb(149, 149, 149);
}

._projectSquare:nth-child(2) {
    animation-delay: 4s;
    left:910px;
    top: 300px;
}
._projectSquare:nth-child(3) {
    animation-delay: 8s;
    left:800px;
    top: 150px;
}
._projectSquare:nth-child(4) {
    animation-delay: 12s;
    left:410px;
    top: 140px;
}
._projectSquare:nth-child(5) {
    animation-delay: 16s;
    left:210px;
    top: 40px;
}
._projectSquare:nth-child(6) {
    animation-delay: 20s;
    left:710px;
    top: 210px;
}
._projectSquare:nth-child(7) {
    animation-delay: 24s;
    left:90px;
    top: 280px;
}


._blogSection {
    background-image: url('../static/pixelLibrary.png');
}
._blogCaption {
    text-align: center;
    padding-top: 50px;
}

._blogCaptionTitle {
    font-family:cursive;
    font-size: 50px;
    padding-bottom: 30px;
}

._blogCaptionBody {
    padding-bottom: 50px;
    font-family: cursive;
}

._blogSectionImage {
    height: 100px;
    width: 100px;
    &:hover {
        content: url('../static/openBook.png');
    }
}



._mainImgL {
    width: 50vw;
}
._mainImgR {
    width: 50vw;

}


._hulusiSectionDiv {
    background-image: url('../static/hulusiBack.jpg');
    display: flex;
}


._hulusiPic {
    margin-top: 60px;
    height: 250px;
    width: 250px;
}
._hulusiInfo {
    width: 400px;
}

._hulusiDescHeader {
    margin-top: 30px;
    font-size: 35px;
}

._hulusiDescBody {
    padding-top: 20px;
    font-size: 20px;
}

@keyframes aura {
    0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
      }
      100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
      }
}

._hulusiEmailIcon {
    margin-top: 60px;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    &:hover {
        animation: aura 1s infinite;
    }
}